<template>
  <div>
    <div class="home_bg">
      <img src="../../static/image/mobile/m_home_head.png" />
    </div>
    <div class="home_news">
      <img
        class="home_news_title"
        src="../../static/image/mobile/m_home_news.png"
      />
      <div class="home_news_list">
        <div class="home_news_list_bg">
          <img src="../../static/image/mobile/m_home_news_head.png" />
          <div class="home_news_list_bg_all">
            <div
              :class="activeId == 0 ? 'isActive' : ''"
              @click="changeType(0)"
            >
              新闻资讯
            </div>
            <div
              :class="activeId == 1 ? 'isActive' : ''"
              @click="changeType(1)"
            >
              信息披露
            </div>
            <div
              :class="activeId == 2 ? 'isActive' : ''"
              @click="changeType(2)"
            >
              购销指南
            </div>
          </div>
        </div>
        <div class="home_news_list_all">
          <div
            v-for="item in newsList_m"
            :key="item.id"
            class="home_news_list_all_item"
            @click="toPage('mnewsListDetail', { id: item.id })"
          >
            <div class="home_news_list_all_name">{{ item.title }}</div>
            <div class="home_news_list_all_date">
              {{ item.year }}.{{ item.day }}
            </div>
          </div>
        </div>
        <div class="home_news_list_btn" @click="toPage('mnewsList')">MORE></div>
      </div>
    </div>
    <div class="home_gzs">
      <img src="../../static/image/mobile/m_fxgzs.png" />
      <div class="home_gzs_detail">
        尊敬的交易商：<br />
        【审慎阅读】当您通过四川中峪拍卖交易服务有限责任公司（以下简称“中峪数交”）商品交易平台参与交易时可能获得一定收益…
      </div>
    </div>
    <div class="home_cat">
      <img src="../../static/image/mobile/m_home_trade.png" width="144px" />
      <div class="home_cat_list">
        <div
          v-for="item in newsList4"
          :key="item.id"
          class="home_cat_list_item"
          @click="toPage('mbusinessDetail', { id: item.id })"
        >
          <img
            v-if="item.thumbnail"
            :src="'https://admin.zoneyu.cn/' + item.thumbnail"
            width="170px"
          />
          <div class="home_cat_list_title">{{ item.title }}</div>
        </div>
      </div>
      <div class="home_cat_btn" @click="toPage('mbusiness')">MORE></div>
    </div>
      <img src="../../static/image/mobile/m_foot.png" width="100%" style="display: block;"/>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "index",
  data() {
    return {
      activeId: "0",
      newsList: [],
      newsList1: [],
      newsList3: [],
      newsList4: [],
      newsList_m: [],
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
    this.getToken();
  },
  methods: {
    getToken() {
      const token = localStorage.getItem("token");
      if (token) {
        // 新闻资讯
        this.getAllList();
        setTimeout(() => {
          localStorage.setItem("token", "");
          this.getToken();
        }, 3000);
      } else {
        axios
          .post(`https://admin.zoneyu.cn/api/admin/login`, {
            username: "halo",
            password: "12345678",
          })
          .then((res) => {
            localStorage.setItem("token", res.data.data.access_token);
            // 新闻资讯
            this.getAllList();
          });
      }
    },
    getAllList() {
      this.getList(12);
      this.getList(13);
      // this.getList(14);
      this.getList(15);
      this.getList(16);
    },
    getList(categoryId) {
      axios
        .get(
          `https://admin.zoneyu.cn/api/admin/posts?status=PUBLISHED&page=0&size=10&categoryId=${categoryId}`,
          {
            headers: {
              "Admin-Authorization": localStorage.getItem("token"),
            },
          }
        )
        .then(async (res) => {
          const content = res.data.data.content;
          content.forEach((item) => {
            const updateTime = new Date(item.createTime);
            item.day =
              updateTime.getDate() > 9
                ? updateTime.getDate()
                : "0" + updateTime.getDate();
            item.year =
              updateTime.getFullYear() +
              "." +
              (updateTime.getMonth() + 1 > 9
                ? updateTime.getMonth() + 1
                : "0" + (updateTime.getMonth() + 1));
            item.title =
              item.title.length > 30
                ? item.title.substring(0, 30) + "..."
                : item.title;
          });
          if (categoryId == 12 || categoryId == 8) {
            this.newsList_m = content.splice(0, 2);
          }
          if (categoryId == 13 || categoryId == 9) {
            this.newsList_m = content.splice(0, 2);
          }
          if (categoryId == 15 || categoryId == 10) {
            this.newsList_m = content.splice(0, 2);
          }
          if (categoryId == 16 || categoryId == 11) {
            this.newsList4 = content.splice(0, 4);
          }
        });
    },
    toPage(path, query) {
      this.$router.push({ path, query });
    },
    changeType(type) {
      this.activeId = type;
      if (type == 0) {
        this.getList(12);
      }
      if (type == 1) {
        this.getList(13);
      }
      if (type == 2) {
        this.getList(15);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home_bg {
  img {
    width: 100%;
    height: 250px;
  }
}
.home_news {
  width: 100%;
  margin: 50px auto;
  text-align: center;
  .home_news_title {
    width: 85px;
    height: 56px;
  }
  .home_news_list {
    margin-top: 35px;
    .home_news_list_bg {
      position: relative;
      img {
        width: 100%;
        height: 200px;
      }
      .home_news_list_bg_all {
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 100%;
        height: 45px;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #252525;
        line-height: 45px;
        letter-spacing: 1px;
        justify-content: space-evenly;
        div {
          width: 33.3%;
        }
        .isActive {
          background-color: #9e1307;
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
    .home_news_list_all {
      text-align: left;
      .home_news_list_all_item {
        margin: 22px 12px;
        border-bottom: 1px solid #f7f7f7;
      }
      .home_news_list_all_name {
        font-size: 16px;
        font-weight: 500;
        color: #1d1b19;
        line-height: 25px;
      }
      .home_news_list_all_date {
        margin-top: 8px;
        margin-bottom: 12px;
        font-size: 12px;
        color: #abaeb0;
        line-height: 16px;
      }
    }
    .home_news_list_btn {
      margin: 12px;
      width: calc(100% - 24px);
      height: 32px;
      background: #f7f7f7;
      text-align: center;
      line-height: 32px;
      font-size: 12px;
    }
  }
}
.home_gzs {
  img {
    width: 100vw;
  }
  .home_gzs_detail {
    width: calc(100% - 24px);
    background: #f7f7f7;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #1d1b19;
    line-height: 28px;
  }
}
.home_cat {
  padding: 50px 12px;
  text-align: center;
  .home_cat_list {
    padding: 40px 0 0 0;
    .home_cat_list_item {
      cursor: pointer;
      margin-bottom: 20px;
      display: inline-block;
    }
    .home_cat_list_title {
      font-size: 12px;
      font-weight: bold;
      color: #1d1b19;
      line-height: 16px;
      text-align: left;
      margin-top: 12px;
    }
    .home_cat_list_item:nth-child(2n + 1) {
      margin-right: 10px;
    }
  }
  .home_cat_btn {
    width: 100%;
    height: 32px;
    background: #f7f7f7;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
  }
}
</style>
